<template>
  <div>
    <v-list v-if="pucks !== null && pucks.length > 0">
      <template v-for="puck in pucks">
        <PuckListItem
          :key="puck.uuid"
          :puck="puck"
        />
      </template>
    </v-list>
    <v-list v-else>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ $t('noPucksYet') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>

import PuckListItem from './PuckListItem'

export default {
    name: 'PuckList',
    props: {
      pucks: {
        type: Array,
        default: () => []
      }
    },
    components: {
        PuckListItem
    }
}
</script>
